import { Children, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useResetInputs } from "src/Contexts/hooks/useResetInputs";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import {
  ModalDeleteCore,
  TableRowCore,
  TableCore,
  TdCore,
} from "src/Pages/components";
import {
  AddIcon,
  EditIcon,
  CheckIcon,
  DeleteForeverIcon,
  CurrencyExchangeIcon,
} from "src/Pages/components/Icons/Icons";
import { UseDelete } from "src/Pages/components/ModalDeleteCore/Hooks/UseDelete";
import { ActionPopoverTable } from "src/Pages/components/table/ActionPopover/ActionPopover";
import { handleFormatDateDayWeek } from "src/shared/Utils/FormatDate/FormatDateToDayWeek";
import { FormatDateBR } from "src/shared/Utils/FormatDateBR";
import { FormatMoney } from "src/shared/Utils/FormatMoney";
import { handleFormatDateIfIsNull } from "src/shared/Utils/handleFormatDateIfIsNull";
import { cpfOurCnpj } from "src/shared/Utils/MaskCpfCnpj";

import { UseGetByIdContratos } from "./Hooks/UseGetByIdContratos";
import { useHttpTableContratos } from "./Hooks/useHttpTableContratos";
import { THeadContracts } from "./THead";
import { ModalContracts } from "../Form";
import { ButtonCore } from "src/Pages/components/ButtonCore/ButtonCore";
import { alpha } from "@mui/material";
import { CancelOutlined } from "@mui/icons-material";
import { FilterSidebar } from "../FilterSidebar/FilterSidebar";
import { ModalCancelContract } from "../CancelContract";
import { useHttpApprovedContract } from "./Hooks/useHttpApprovedContract";

export const TableContracts = () => {
  const { handleGet } = useHttpTableContratos();

  const navigate = useNavigate();

  const { listContratos, setDataGetByIdContrato } = useContextContrato();

  const { handleGetById } = UseGetByIdContratos();
  const { resetInputs } = useResetInputs();

  const [openModalContracts, setOpenModalContracts] = useState(false);
  const [openModalCancelContract, setOpenModalCancelContract] = useState(false);

  const {
    setNameSearch,
    itensPerPage,
    currentPage,
    nameSearch,
    attTable,
    setId,
  } = useConfigPageContext();
  const { handleGet: handleApprovedContract } = useHttpApprovedContract();

  const handleGetIdToEdit = (id: string) => {
    resetInputs();
    setId(id);
    setOpenModalContracts(true);
    handleGetById(id);
  };

  useEffect(() => {
    handleGet();
  }, [attTable, itensPerPage, currentPage, nameSearch]);

  const {
    handleDelete,
    open: openModalDelete,
    loading: loadingDelete,
    setId: setIdModalDelete,
    setOpen: setOpenModalDelete,
  } = UseDelete({ url: "/contracts" });

  return (
    <>
      <TableCore
        Modals={
          <>
            <ModalContracts
              openModal={openModalContracts}
              setOpenModal={setOpenModalContracts}
            />
            <ModalCancelContract
              openModal={openModalCancelContract}
              setOpenModal={setOpenModalCancelContract}
            />
            <ModalDeleteCore
              loading={loadingDelete}
              modalOpen={openModalDelete}
              no="Não, Cancelar"
              titlePrimary="Você deseja excluir da lista ?"
              yes="Sim, Deletar !"
              onClickFalse={() => setOpenModalDelete(false)}
              onClickTrue={handleDelete}
            />
          </>
        }
        THead={<THeadContracts />}
        qtdList={listContratos.length}
        setNameSearch={setNameSearch}
        filter={<FilterSidebar />}
        cardAdd={{
          title: "Todos contratos",
          buttons: [
            <ButtonCore
              startIcon={<AddIcon />}
              title="Criar contrato"
              size="small"
              onClick={() => setOpenModalContracts(true)}
            />,
          ],
        }}
      >
        {Children.toArray(
          listContratos.map((item) => {
            return (
              <TableRowCore>
                <TdCore
                  values={
                    <ActionPopoverTable
                      optionsList={[
                        {
                          icon: <CurrencyExchangeIcon fontSize="small" />,
                          title: "Ver Contrato",
                          background: "success",
                          onClick: () => {
                            setDataGetByIdContrato(item);
                            navigate(`/Lancamentos/contrato/${item.id}`);
                          },
                        },
                        item.statusCode === "APPROVED"
                          ? {
                              icon: <EditIcon fontSize="small" />,
                              title: "Cancelar",
                              background: "warning",
                              onClick: () => {
                                setId(String(item.id));
                                setOpenModalCancelContract(true);
                              },
                            }
                          : {
                              icon: <EditIcon fontSize="small" />,
                              title: "Aprovar",
                              background: "warning",
                              onClick: () => {
                                handleApprovedContract(String(item.id));
                              },
                            },
                        {
                          icon: <EditIcon fontSize="small" />,
                          title: "Editar",
                          background: "warning",
                          onClick: () => {
                            setId(String(item.id));
                            handleGetIdToEdit(String(item.id));
                          },
                        },
                        {
                          title: "Excluir",
                          background: "error",
                          onClick: () => {
                            setIdModalDelete(item.id);
                            setOpenModalDelete(true);
                          },
                          icon: <DeleteForeverIcon fontSize="small" />,
                        },
                      ]}
                    />
                  }
                />

                <TdCore
                  padding="5px"
                  values={item.proposalCode}
                  textAlign="center"
                  sxStyle={{
                    background: (theme) =>
                      alpha(theme.palette.primary.main, 0.2),
                    borderRadius: "8px",
                  }}
                />
                <TdCore
                  textAlign="left"
                  tooltip={handleFormatDateDayWeek(
                    handleFormatDateIfIsNull(item.subscriptionDate)
                  )}
                  values={FormatDateBR(item.subscriptionDate)}
                />
                <TdCore
                  isLimit
                  isLimitQTD={35}
                  textAlign="left"
                  values={item.operator?.name}
                  subTitle={item.modality?.name}
                />

                <TdCore
                  sxStyle={{ color: (theme) => theme.custom.color.money }}
                  values={`R$ ${FormatMoney(item.monthlyPayment)}`}
                />
                <TdCore
                  values={
                    item.contractType === "PF" ? item.holder : item.companyName
                  }
                  subTitle={
                    item.contractType === "PF"
                      ? cpfOurCnpj({ text: item.cpf, type: "cpf" })
                      : cpfOurCnpj({ text: item.cnpj, type: "cnpj" })
                  }
                />
                <TdCore
                  subTitle={cpfOurCnpj({
                    text: item.ContractContact.phone1,
                    type: "phone",
                  })}
                  values={cpfOurCnpj({
                    text: item.ContractContact.phone2,
                    type: "phone",
                  })}
                />

                <TdCore
                  isLimit
                  isLimitQTD={35}
                  textAlign="left"
                  values={item.administrator?.name}
                />
                <TdCore
                  tooltip={item.cancelDescription}
                  values={
                    item.statusCode === "APPROVED" ? (
                      <CheckIcon color="success" />
                    ) : (
                      <CancelOutlined color="error" />
                    )
                  }
                />
              </TableRowCore>
            );
          })
        )}
      </TableCore>
    </>
  );
};
