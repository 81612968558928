import { Children, useEffect, useState } from "react";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { ModalDeleteCore, TableCore, TdCore } from "src/Pages/components";
import {
  EditIcon,
  BackspaceIcon,
  DeleteForeverIcon,
  CurrencyExchangeIcon,
} from "src/Pages/components/Icons/Icons";
import { UseDelete } from "src/Pages/components/ModalDeleteCore/Hooks/UseDelete";
import { TableRowCore } from "src/Pages/components/table/@Core/TableRowCore/TableRowCore";
import { ActionPopoverTable } from "src/Pages/components/table/ActionPopover/ActionPopover";
import { FormatDateBR, GetDateUSSemValue } from "src/shared/Utils/FormatDateBR";
import { cpfOurCnpj } from "src/shared/Utils/MaskCpfCnpj";

import { ModalCancelReceivedInstallment } from "../Components/ModalCancelReceivedInstallment/ModalCancelReceivedInstallment";
import { ModalEditarParcela } from "../Components/ModalEditarParcela";
import { ModalNovaParcela } from "../Components/ModalNovaParcela";
import { ModalReceivedInstallment } from "../Components/ModalParcelaReceber";

import { useHttpTableInstallments } from "./Hooks/useHttpTableInstallments";
import { THeadInstallments } from "./THead";
import { FormatMoney } from "src/shared/Utils/FormatMoney";
import { IInstallments } from "src/Contexts/Lancamentos/Contrato/ContratosTypes";
import { handleFormatDateIfIsNull } from "src/shared/Utils/handleFormatDateIfIsNull";
import { FilterSidebarInstallments } from "../Components/FilterSidebar/FilterSidebarInstallments";

interface ITableInstallments {
  idContract?: string;
}

export const TableInstallments = ({ idContract: id }: ITableInstallments) => {
  const { handleGet } = useHttpTableInstallments();

  const {
    setNameSearch,
    itensPerPage,
    currentPage,
    nameSearch,
    attTable,
    setId,
  } = useConfigPageContext();

  const {
    getInstallments,
    setValuesReceivedInstallment,
    setValuesInputUpdateInstallment,
  } = useContextContrato();

  const [openModalReceberParcela, setOpenModalReceberParcela] = useState(false);
  const [openModalEditarParcela, setOpenModalEditarParcela] = useState(false);
  const [
    openModalCancelReceivedInstallment,
    setOpenModalCancelReceivedInstallment,
  ] = useState(false);

  useEffect(() => {
    handleGet(id);
  }, [attTable, itensPerPage, currentPage, nameSearch]);

  const {
    handleDelete,
    open: openModalDelete,
    loading: loadingDelete,
    setId: setIdModalDelete,
    setOpen: setOpenModalDelete,
  } = UseDelete({ url: "/installments" });

  const handleReceberParcela = (item: IInstallments) => {
    setId(String(item.id));
    setOpenModalReceberParcela(true);
    setValuesReceivedInstallment({
      valuePayment: FormatMoney(String(item.contractValue)),
      dateReceived: `${GetDateUSSemValue()}`,
      id: String(item.id),
      lives: item.lives,
      note: "",
    });
  };

  const handleUpdateInstallment = (item: IInstallments) => {
    setOpenModalEditarParcela(true);

    setValuesInputUpdateInstallment({
      monthlyPayment: FormatMoney(String(item.contractValue)),
      installmentNumber: String(item.installmentNumber),
      dueDate: handleFormatDateIfIsNull(item.dueDate),
      commission: String(item.commissionPercentage),
      lives: String(item.lives),
      id: String(item.id),
    });
  };

  if (!getInstallments) return <></>;

  return (
    <TableCore
      Modals={
        <>
          <ModalReceivedInstallment
            openModal={openModalReceberParcela}
            setOpenModal={setOpenModalReceberParcela}
          />
          <ModalCancelReceivedInstallment
            modalDelete={openModalCancelReceivedInstallment}
            setModalDelete={setOpenModalCancelReceivedInstallment}
          />
          <ModalEditarParcela
            openModal={openModalEditarParcela}
            setOpenModal={setOpenModalEditarParcela}
          />
          <ModalDeleteCore
            loading={loadingDelete}
            modalOpen={openModalDelete}
            no="Não, Cancelar"
            titlePrimary="Você deseja excluir parcela ?"
            yes="Sim, Deletar !"
            onClickFalse={() => setOpenModalDelete(false)}
            onClickTrue={() => handleDelete()}
          />
        </>
      }
      THead={<THeadInstallments />}
      cardAdd={{
        buttons: [<ModalNovaParcela id={id as string} />],
      }}
      qtdList={getInstallments.meta.total}
      setNameSearch={setNameSearch}
      filter={<FilterSidebarInstallments />}
    >
      {Children.toArray(
        getInstallments.data.map((item) => {
          return (
            <TableRowCore>
              <TdCore
                values={
                  <ActionPopoverTable
                    optionsList={[
                      {
                        icon: <CurrencyExchangeIcon fontSize="small" />,
                        title: "Receber",
                        background: "success",
                        isDisabled: item.isInstallmentReceived,
                        onClick: () => handleReceberParcela(item),
                      },
                      {
                        icon: (
                          <BackspaceIcon
                            fontSize="small"
                            sx={{ fontSize: ".9rem" }}
                          />
                        ),
                        background: "warning",
                        title: "Zerar recebimento",
                        onClick: () => {
                          setId(String(item.id));
                          setOpenModalCancelReceivedInstallment(true);
                        },
                        isDisabled: !item.isInstallmentReceived,
                      },
                      {
                        icon: <EditIcon fontSize="small" />,
                        title: "Editar",
                        background: "warning",
                        onClick: () => {
                          handleUpdateInstallment(item);
                        },
                      },
                      {
                        icon: <DeleteForeverIcon fontSize="small" />,
                        title: "Excluir",
                        background: "error",
                        onClick: () => {
                          setIdModalDelete(item.id);
                          setOpenModalDelete(true);
                        },
                      },
                    ]}
                  />
                }
              />
              <TdCore values={item.contract.operator.name} />
              <TdCore values={item.contract.proposalCode} />
              <TdCore textAlign="center" values={item.installmentNumber} />
              <TdCore textAlign="center" values={item.lives} />
              <TdCore textAlign="center" values={FormatDateBR(item.dueDate)} />

              <TdCore
                sxStyle={{ color: (theme) => theme.custom.color.money }}
                values={
                  item.isInstallmentReceived
                    ? `R$ ${FormatMoney(item.valuePayment) || ""}`
                    : ""
                }
              />
              <TdCore
                textAlign="center"
                values={
                  item.dateReceived ? FormatDateBR(item.dateReceived) : ""
                }
              />
              <TdCore
                values={`R$ ${FormatMoney(item.contractValue)}`}
                sxStyle={{ color: (theme) => theme.custom.color.money }}
              />
              <TdCore
                values={`R$ ${FormatMoney(item.commissionValue)}`}
                sxStyle={{ color: (theme) => theme.custom.color.money }}
              />
              <TdCore
                textAlign="center"
                values={`${item.commissionPercentage || "0,00%"}%`}
                sxStyle={{ color: (theme) => theme.custom.color.percent }}
              />
              <TdCore
                values={
                  item.contract.contractType === "PF"
                    ? item.contract.holder
                    : item.contract.companyName
                }
                subTitle={
                  item.contract.contractType === "PF"
                    ? cpfOurCnpj({ text: item.contract.cpf, type: "cpf" })
                    : cpfOurCnpj({ text: item.contract.cnpj, type: "cnpj" })
                }
              />
            </TableRowCore>
          );
        })
      )}
    </TableCore>
  );
};
