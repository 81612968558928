import { ChangeEvent } from "react";
import { Grid } from "@mui/material";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { IValuesCancelContract } from "src/Contexts/Lancamentos/Contrato/ContratosTypes";
import { AppTextField } from "src/Pages/components";

export const FormCancelContract = () => {
  const { valuesCancelContract, setValuesCancelContract } =
    useContextContrato();

  const handleChange =
    (prop: keyof IValuesCancelContract) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      setValuesCancelContract((prev) => ({
        ...prev,
        [prop]: event.target.value,
      }));
    };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <AppTextField
          fullWidth
          label="Vencimento *"
          type="date"
          value={valuesCancelContract?.cancelDate}
          onChange={handleChange("cancelDate")}
        />
      </Grid>
      <Grid item xs={12}>
        <AppTextField
          fullWidth
          label="Descrição"
          value={valuesCancelContract?.cancelDescription}
          onChange={handleChange("cancelDescription")}
        />
      </Grid>
    </Grid>
  );
};
