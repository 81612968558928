import { GetDateUSSemValue } from "src/shared/Utils/FormatDateBR";

import {
  ICreateContrato,
  IReceivedInstallment,
  IValuesCancelContract,
  IValuesFilterContracts,
  IValuesFilterInstallments,
} from "../ContratosTypes";

export const valueDefaultInputContratos: ICreateContrato = {
  commissionGrade: "",
  subscriptionDate: `${GetDateUSSemValue()}`,
  proposalCode: "",
  contractType: "PF",

  companyCode: "",
  administratorCode: "",
  operatorCode: "",
  modalityCode: "",

  cpf: "",
  holder: "",
  rg: "",

  maritalStatus: "NONE",
  birthDate: "",
  gender: "NONE",
  motherName: "",

  guardianName: "",
  guardianCpf: "",
  guardianBirthDate: "",

  cnpj: "",
  companyName: "",

  email: "",
  phone1: "",
  phone2: "",

  postalCode: "",
  street: "",
  number: "",
  neighborhood: "",
  city: "",
  state: "",
  complement: "",

  issuanceDate: `${GetDateUSSemValue()}`,
  startDate: `${GetDateUSSemValue()}`,
  firstInstallmentDueDate: `${GetDateUSSemValue()}`,
  expirationDate: "",

  monthlyPayment: "0.00",

  lives: "1",

  notes: "",
};

export const valueDefaultInputReceivedInstallments: IReceivedInstallment = {
  id: "",
  valuePayment: "",
  dateReceived: `${GetDateUSSemValue()}`,
  note: "",
  lives: 1,
};
export const valueDefaultInputCancelContracts: IValuesCancelContract = {
  cancelDescription: "",
  cancelDate: `${GetDateUSSemValue()}`,
};

export const valuesDefaultFilterContrato: IValuesFilterContracts = {
  proposalCode: "",
  startDateSignature: "",
  endDateSignature: "",
  startDateExpiration: "",
  endDateExpiration: "",
  cpfAndCNPJ: "",
  holderNameAndCompanyName: "",
  status: undefined,
};

export const valuesDefaultFilterInstallments: IValuesFilterInstallments = {
  startSignatureDate: "",
  endSignatureDate: "",

  endDateReceipt: "",
  endDueDate: "",

  startDateReceipt: "",
  startDueDate: "",

  endDateEmission: "",
  startDateEmission: "",

  customerName: "",
  customerDocument: "",

  proposalCode: "",
  installmentNumber: "",
  installmentReceipt: "ALL",
};
