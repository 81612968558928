import { alpha } from "@mui/material";
import {
  ResponsiveContainer,
  CartesianGrid,
  BarChart,
  Tooltip,
  Legend,
  XAxis,
  YAxis,
  Bar,
} from "recharts";
import { CardCore } from "src/Pages/components/CardCore/CardCore";
import { CardBodyCore } from "src/Pages/components/CardCore/Components/CardBodyCore";

export interface MixBarChartProp {
  data: {
    name: string;
    AS: number;
    Rev: number;
  }[];
}

const data = [
  { name: "Page A", Expense: 4000, Incoming: 2400 },
  { name: "Page B", Expense: 3000, Incoming: 1398 },
  { name: "Page C", Expense: 2000, Incoming: 9800 },
  { name: "Page D", Expense: 2780, Incoming: 3908 },
  { name: "Page E", Expense: 1890, Incoming: 4800 },
  { name: "Page F", Expense: 2390, Incoming: 3800 },
  { name: "Page G", Expense: 3490, Incoming: 4300 },
  { name: "Page H", Expense: 1490, Incoming: 2300 },
];

export const BarChartIncomingExpense = () => {
  const colorExpense = "#fc5f61";
  const colorIncoming = "#4a90e2";
  const colorStroke = "#b8b8b8";

  return (
    <CardCore sxStyle={{ margin: "0", height: "100%" }}>
      <CardBodyCore
        // title="Entrada / Saída"
        title="Em breve (em desenvolvimento)"
      >
        <ResponsiveContainer height={300} width="100%">
          <BarChart
            barSize={15}
            data={data}
            margin={{
              top: 20,
              right: 0,
              left: -10,
              bottom: 5,
            }}
          >
            <CartesianGrid
              horizontal
              stroke={alpha(colorStroke as string, 0.7)}
              vertical={false}
            />

            <XAxis dataKey="name" fontSize={14} stroke={colorStroke} />
            <YAxis fontSize={14} stroke={colorStroke} tickLine={false} />

            <Tooltip />
            <Legend />
            <Bar
              dataKey="Expense"
              fill={colorExpense}
              name="Saída"
              radius={6}
            />
            <Bar
              dataKey="Incoming"
              fill={colorIncoming}
              name="Entrada"
              radius={5}
            />
          </BarChart>
        </ResponsiveContainer>
      </CardBodyCore>
    </CardCore>
  );
};
