import { useState } from "react";
import { useContextDashboardContext } from "src/Contexts/Dashboard/ContextDashboardBroker";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

export const UseFormPieGraphicOperators = () => {
  const { handleGetAlert } = useLayoutMainContext();

  const {
    filterMetricsFilterDashboard: { endDate, startDate },
    setGraphicOperators,
  } = useContextDashboardContext();

  const [loading, setLoading] = useState(false);

  const handleCreatePerfilAcesso = async () => {
    setLoading(true);

    return api
      .get(`/metrics/operators?startDate=${startDate}&endDate=${endDate}`)
      .then((res) => {
        setGraphicOperators(res.data);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  return { handleSubmit: handleCreatePerfilAcesso, loading };
};
