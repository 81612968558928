import { createContext, useContext, useState, FC, ReactNode } from "react";

import {
  IDataConfirmationAdministrators,
  IInstallmentsReceivedPrimary,
  IDataConfirmationOperator,
  IDataConfirmationFiliais,
  IMetricsFilterDashboard,
  IContextDashboardBroker,
  IDataProfitableValues,
  IInstallmentsReceived,
  IPieGraphicOperators,
  IMetricsDashboard,
} from "./ContextDashboardBrokerTypes";
import { valueDefaultFilterMetricsDashboard } from "./ValuesDefaultContextProfile/ValuesDefaultContextDashboardBroker";

const ContextDashboardProvider = createContext({} as IContextDashboardBroker);

const ContextDashboardBrokerProviderContext: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [dataProfitableValues, setDataProfitableValues] =
    useState<IDataProfitableValues>({ data: [{ label: "", value: 0 }] });

  const [dataConfirmationFiliais, setDataConfirmationFiliais] =
    useState<IDataConfirmationFiliais>({ data: [], meta: { total: "" } });

  const [dataConfirmationOperator, setDataConfirmationOperator] =
    useState<IDataConfirmationOperator>({ data: [], meta: { total: "" } });
  const [dataConfirmationAdministrators, setDataConfirmationAdministrators] =
    useState<IDataConfirmationAdministrators>({
      data: [],
      meta: { total: "" },
    });

  const [dataInstallmentsReceived, setDataInstallmentsReceived] =
    useState<IInstallmentsReceived>({
      data: [{ label: "", value: 0 }],
      meta: { total: 0 },
    });

  const [dataInstallmentsReceivedPrimary, setDataInstallmentsReceivedPrimary] =
    useState<IInstallmentsReceivedPrimary>({
      data: [{ label: "", value: 0 }],
      meta: { total: 0 },
    });

  const [filterMetricsFilterDashboard, setFilterMetricsFilterDashboard] =
    useState<IMetricsFilterDashboard>(valueDefaultFilterMetricsDashboard);

  const [metricsDashboard, setMetricsDashboard] = useState<IMetricsDashboard>({
    installmentNotReceived: undefined,
    installmentReceived: undefined,
    contractsCanceled: undefined,
    contracts: undefined,
  });

  const [graphicOperators, setGraphicOperators] =
    useState<IPieGraphicOperators>({
      data: [{ name: "", percentage: 0, total: 0, totalMonthly: 0 }],
      meta: { totalContracts: 0 },
    });

  return (
    <ContextDashboardProvider.Provider
      value={{
        setDataConfirmationAdministrators,
        setDataInstallmentsReceivedPrimary,
        dataInstallmentsReceivedPrimary,
        setFilterMetricsFilterDashboard,
        dataConfirmationAdministrators,
        filterMetricsFilterDashboard,
        setDataInstallmentsReceived,
        setDataConfirmationOperator,
        setDataConfirmationFiliais,
        dataConfirmationOperator,
        dataInstallmentsReceived,
        dataConfirmationFiliais,
        setDataProfitableValues,
        dataProfitableValues,
        setMetricsDashboard,
        setGraphicOperators,
        metricsDashboard,
        graphicOperators,
      }}
    >
      {children}
    </ContextDashboardProvider.Provider>
  );
};

const useContextDashboardContext = (): IContextDashboardBroker =>
  useContext(ContextDashboardProvider);

export { useContextDashboardContext, ContextDashboardBrokerProviderContext };
