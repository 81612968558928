import { Children, useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { ModalDeleteCore, TableCore, TdCore } from "src/Pages/components";
import { DeleteForeverIcon, EditIcon } from "src/Pages/components/Icons/Icons";

import { UseDelete } from "src/Pages/components/ModalDeleteCore/Hooks/UseDelete";
import { TableRowCore } from "src/Pages/components/table/@Core/TableRowCore/TableRowCore";
import { ActionPopoverTable } from "src/Pages/components/table/ActionPopover/ActionPopover";

import { useHttpTableLeads } from "./Hooks/useHttpTableLeads";
import { THeadLeads } from "./THead";
import { ModalLeads } from "../Form";
import { ButtonCore } from "src/Pages/components/ButtonCore/ButtonCore";
import { useContextLeads } from "src/Contexts/Leads/LeadsContext";
import { cpfOurCnpj } from "src/shared/Utils/MaskCpfCnpj";
import {
  OBjLeadsInterestLevel,
  objLeadsSourceCollection,
  objLeadsStatus,
} from "src/Pages/components/ObjTransform";
import { alpha } from "@mui/material";
import { IListLeads } from "src/Contexts/Leads/LeadsTypes";
import { useResetInputs } from "src/Contexts";
import { UseSetGetLeadsById } from "./Hooks/UseGetByIdContratos";
import { FormatDateBR } from "src/shared/Utils/FormatDateBR";

export const TableLeads = () => {
  const { resetInputs } = useResetInputs();
  const { listLeads } = useContextLeads();
  const { handleSetDateLeadsGetById } = UseSetGetLeadsById();

  const {
    attTable,
    setId,
    setNameSearch,
    itensPerPage,
    currentPage,
    nameSearch,
  } = useConfigPageContext();
  const { handleGet } = useHttpTableLeads();

  const [openModalLeads, setOpenModalLeads] = useState(false);

  const handleGetIdToEdit = (id: string, data: IListLeads) => {
    resetInputs();
    setId(id);
    setOpenModalLeads(true);
    handleSetDateLeadsGetById(data);
  };

  useEffect(() => {
    handleGet();
  }, [attTable, itensPerPage, currentPage, nameSearch]);

  const {
    handleDelete,
    open: openModalDelete,
    loading: loadingDelete,
    setId: setIdModalDelete,
    setOpen: setOpenModalDelete,
  } = UseDelete({ url: "/leads" });

  return (
    <TableCore
      heightTable="420px"
      Modals={
        <>
          <ModalLeads
            openModal={openModalLeads}
            setOpenModal={setOpenModalLeads}
          />
          <ModalDeleteCore
            loading={loadingDelete}
            modalOpen={openModalDelete}
            no="Não, Cancelar"
            titlePrimary="Você deseja excluir da lista ?"
            yes="Sim, Deletar !"
            onClickFalse={() => setOpenModalDelete(false)}
            onClickTrue={() => handleDelete()}
          />
        </>
      }
      THead={<THeadLeads />}
      qtdList={listLeads.length}
      setNameSearch={setNameSearch}
      cardAdd={{
        title: "Leads",
        buttons: [
          <ButtonCore
            size="small"
            startIcon={<AddIcon />}
            title="Criar"
            onClick={() => setOpenModalLeads(true)}
          />,
        ],
      }}
    >
      {Children.toArray(
        listLeads.map((item) => {
          return (
            <TableRowCore id={String(item.id)}>
              <TdCore
                values={
                  <ActionPopoverTable
                    optionsList={[
                      {
                        icon: <EditIcon fontSize="small" />,
                        title: "Editar",
                        background: "warning",
                        onClick: () => {
                          handleGetIdToEdit(String(item.id), item);
                        },
                      },
                      {
                        icon: <DeleteForeverIcon fontSize="small" />,
                        title: "Excluir",
                        background: "error",
                        onClick: () => {
                          setIdModalDelete(item.id);
                          setOpenModalDelete(true);
                        },
                      },
                    ]}
                  />
                }
              />
              <TdCore
                values={item.name}
                avatar={item.name}
                subTitle={item.email}
              />

              <TdCore
                textAlign="left"
                values={cpfOurCnpj({ text: item.whats, type: "phone" })}
                subTitle={cpfOurCnpj({ text: item.telefone1, type: "phone" })}
              />

              <TdCore
                textAlign="center"
                sxStyle={{
                  background: alpha(
                    OBjLeadsInterestLevel[item.interestLevel].color,
                    0.3
                  ),
                  borderRadius: "8px",
                }}
                values={OBjLeadsInterestLevel[item.interestLevel].title}
              />
              <TdCore textAlign="left" values={objLeadsStatus[item.status]} />
              <TdCore
                textAlign="left"
                values={objLeadsSourceCollection[item.sourceCollection]}
              />

              <TdCore textAlign="left" values={FormatDateBR(item.captureAt)} />
            </TableRowCore>
          );
        })
      )}
    </TableCore>
  );
};
