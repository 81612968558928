import { useState } from "react";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

export interface IValueProfitDashboard {
  data: {
    id: number;
    dateReceived: string;
    lives: number;
    commissionValue: number;
    valuePayment: number;
    installmentNumber: number;
  }[];
  totalCommissionValue: number;
}

export const UseContractInternalDashboard = (id: string) => {
  const { handleGetAlert } = useLayoutMainContext();

  const [valueProfitDashboard, setValueProfitDashboard] =
    useState<IValueProfitDashboard>();

  const [loading, setLoading] = useState(false);

  const handleCreatePerfilAcesso = async () => {
    setLoading(true);

    return api
      .get(`/metrics/installments-paid/${id}`)
      .then((res) => {
        setValueProfitDashboard(res.data);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  return {
    handleSubmit: handleCreatePerfilAcesso,
    loading,
    valueProfitDashboard,
  };
};
