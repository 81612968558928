import { alpha, Typography, useTheme } from "@mui/material";
import { Children } from "react";
import {
  ResponsiveContainer,
  CartesianGrid,
  LineChart,
  Tooltip,
  XAxis,
  Line,
  YAxis,
} from "recharts";
import { useContextDashboardContext } from "src/Contexts/Dashboard/ContextDashboardBroker";
import { CardCore } from "src/Pages/components/CardCore/CardCore";
import { CardBodyCore } from "src/Pages/components/CardCore/Components/CardBodyCore";

interface TooltipProps {
  active?: boolean;
  payload?: Array<{
    payload: {
      installmentNumber?: string;
      value?: number;
      receivedLabel: string;
    };
    name: string;
    value: number;
  }>;
  labelStyle?: React.CSSProperties;
}

const CustomTooltip: React.FC<TooltipProps> = ({
  active,
  payload,
  labelStyle,
}) => {
  console.log({
    active,
    payload,
    labelStyle,
  });
  if (!active || !payload || payload.length === 0) {
    return null;
  }

  const data = payload[0].payload;

  return (
    <CardCore>
      <Typography style={labelStyle}>
        {`Data do recebimento: ${data.receivedLabel.split("-")[0]} ${
          data.receivedLabel.split("-")[1]
            ? `- ${data.receivedLabel.split("-")[1]}`
            : ""
        }      `}
      </Typography>

      {Children.toArray(
        payload.map((item) => {
          return <Typography>{`${item.name}: ${item.value}`}</Typography>;
        })
      )}
    </CardCore>
  );
};

export const InstallmentsReceivedPrimary = () => {
  const { dataInstallmentsReceivedPrimary, dataInstallmentsReceived } =
    useContextDashboardContext();

  const theme = useTheme();

  const dataGraphic = [
    ...dataInstallmentsReceivedPrimary.data.map((item, index) => {
      return {
        receivedPrimaryLabel: item.label,
        receivedPrimaryValue: item.value,
        receivedLabel: dataInstallmentsReceived.data[index].label,
        receivedValue: dataInstallmentsReceived.data[index].value,
      };
    }),
  ];

  const colorStroke = "#b8b8b8";

  return (
    <CardCore sxStyle={{ margin: "1rem 0", marginBottom: "24px" }}>
      <CardBodyCore
        title={`Parcelas recebidas - Total: ${
          dataInstallmentsReceived.meta.total || 0
        }`}
      >
        <ResponsiveContainer height={350} width="100%">
          <LineChart
            data={dataGraphic}
            margin={{ top: 0, right: 0, left: -10, bottom: 0 }}
          >
            <XAxis // barra de baixo
              dataKey="receivedLabel"
              fontSize={14}
              stroke={colorStroke}
            />

            <YAxis // barra lateral com valores
              fontSize={14}
              stroke={colorStroke}
              tickLine={false}
            />

            <CartesianGrid
              horizontal
              stroke={alpha(colorStroke as string, 0.7)}
              strokeDasharray="3 3"
            />

            <Tooltip // tooltip
              labelStyle={{ color: colorStroke }}
              content={<CustomTooltip />}
            />

            <Line
              dot
              dataKey="receivedValue"
              fill="url(#colorPv)"
              name="Confirmação de parcela geral"
              stroke={theme.palette.primary.main}
              strokeWidth={2}
              type="bump"
            />
            <Line
              dot
              dataKey="receivedPrimaryValue"
              fill="url(#colorPv)"
              name="Confirmação apenas de primeira"
              stroke="#00DDA3"
              strokeWidth={2}
              type="bump"
            />
          </LineChart>
        </ResponsiveContainer>
      </CardBodyCore>
    </CardCore>
  );
};
