import { Box, Typography } from "@mui/material";
import { CardCore } from "../../CardCore/CardCore";
import React from "react";
interface ICardTable {
  isActive?: boolean;
  title: string;
  value1: string | number;
  onClick?: () => void;
  value2?: string;
  arrow?: React.ReactNode;
  isHoverBackground?: boolean;

  icon?: {
    icon: React.ReactNode;
    color: string;
  };
}

export const CardTable = ({
  icon,
  title,
  onClick,
  value1,
  isActive,
  value2,
  arrow,
  isHoverBackground,
}: ICardTable) => {
  return (
    <CardCore
      onClick={onClick}
      sxStyle={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        margin: 0,
        cursor: "pointer",
        height: "100%",

        background: (theme) =>
          isActive ? theme.custom.gradient.cardSecondary : "",

        "&:hover": {
          background: (theme) =>
            isHoverBackground || isActive
              ? theme.custom.gradient.cardSecondary
              : "",
        },
      }}
    >
      <Box
        sx={{
          "& > *": {
            color: (theme) => (isActive ? "white" : theme.palette.text.primary),
          },
        }}
      >
        <Typography
          sx={{
            fontSize: { sx: "8px", sm: "12px", md: "16px" },
            mb: { xs: 0.7, sm: 1.8 },
          }}
        >
          {title}
        </Typography>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            sx={{ fontSize: { sx: "12px", md: "20px" }, fontWeight: "500" }}
          >
            {value1}
          </Typography>
          <Box sx={{ marginLeft: "0px" }}>{arrow && arrow}</Box>
        </Box>

        <Typography sx={{ fontSize: { sx: "7px", sm: "10px", md: "14px" } }}>
          {value2}
        </Typography>
      </Box>

      {icon && (
        <Box
          sx={{
            borderRadius: "50%",
            background: icon?.color,
            padding: "8px",
            width: { xs: "40px", sm: "50px" },
            height: { xs: "40px", sm: "50px" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            transition: "transform 0.3s ease",
            "&:hover": {
              transform: "scale(1.2)",
            },
          }}
        >
          {icon?.icon}
        </Box>
      )}
    </CardCore>
  );
};
