import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { IGetInstallments } from "src/Contexts/Lancamentos/Contrato/ContratosTypes";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";
import { apenasNumeros } from "src/shared/Utils/FormatMoney";

export const useHttpTableInstallments = () => {
  const { handleGetAlert } = useLayoutMainContext();
  const { setGetInstallments, valuesFilterInstallments } = useContextContrato();

  const {
    setQtdRegisters,
    setLoadingTable,
    itensPerPage,
    currentPage,
    nameSearch,
  } = useConfigPageContext();

  const handleGet = async (idContract?: string) => {
    setLoadingTable(true);

    api
      .get<IGetInstallments>(
        `/installments?contractId=${idContract}&page=${currentPage}&pageSize=${itensPerPage}&filter=${nameSearch}&customerName=${
          valuesFilterInstallments.customerName
        }&customerDocument=${apenasNumeros(
          valuesFilterInstallments.customerDocument
        )}&proposalCode=${
          valuesFilterInstallments.proposalCode
        }&startSignatureDate=${
          valuesFilterInstallments.startSignatureDate
        }&endSignatureDate=${valuesFilterInstallments.startSignatureDate}
        &startDueDate=${valuesFilterInstallments.startDueDate}
        &endDueDate=${valuesFilterInstallments.endDueDate}
        &startDateReceipt=${valuesFilterInstallments.startDateReceipt}
        &endDateReceipt=${valuesFilterInstallments.endDateReceipt}`
      )
      .then((data) => {
        setGetInstallments(data.data);
        setQtdRegisters(data.data.meta.total);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoadingTable(false));
  };

  return { handleGet };
};
