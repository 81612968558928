import moment from "moment";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { LinkCore } from "../LinkCore/LinkCore";
import { UrlConfig } from "src/shared/Utils/paths";
import { ButtonCore } from "../ButtonCore/ButtonCore";
import { FormatDateBR } from "src/shared/Utils/FormatDateBR";
import { alpha, Typography } from "@mui/material";
import { CardCore } from "../CardCore/CardCore";

export const HeaderIsPlanExpired = () => {
  const { userPerfil } = useAuthContext();

  const isViewModal =
    userPerfil?.subscription?.isActive === false ||
    userPerfil?.subscription === null;

  return (
    <>
      {isViewModal &&
        moment().isAfter(moment(userPerfil.createdAt).add(7, "days")) && (
          <CardCore
            sxStyle={{
              border: (theme) =>
                `3px solid ${alpha(theme.palette.primary.main, 0.8)}`,
              borderRadius: 2,
              textAlign: "center",
            }}
          >
            <Typography sx={{ mb: 2, fontSize: { xs: "12px", md: "18px" } }}>
              {`Seu período de teste terminou em ${FormatDateBR(
                moment(userPerfil.createdAt).add(7, "days").format("YYYY-MM-DD")
              )}. Para continuar aproveitando todos os recursos, contrate um plano.`}
            </Typography>

            <LinkCore key={1} to={UrlConfig.plans.url}>
              <ButtonCore title="Conhecer planos" />
            </LinkCore>
          </CardCore>
        )}
    </>
  );
};
