import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

export const useHttpApprovedContract = () => {
  const { handleGetAlert } = useLayoutMainContext();

  const { setAttTable, setLoadingTable } = useConfigPageContext();

  const handleGet = async (id: string) => {
    setLoadingTable(true);

    return api
      .patch(`/contracts/approved/${id}`)
      .then((res) => {
        handleGetAlert({ message: res.data.message });
        setAttTable((item) => !item);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoadingTable(false));
  };

  return { handleGet };
};
