import { useState } from "react";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

interface IHandleModalCancelContract {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UseFormModalCancelContract = ({
  setOpenModal,
}: IHandleModalCancelContract) => {
  const { setAttTable, id } = useConfigPageContext();
  const { handleGetAlert } = useLayoutMainContext();
  const { valuesCancelContract } = useContextContrato();

  const [loading, setLoading] = useState(false);

  const handleCancelContract = async () => {
    setLoading(true);

    await api
      .patch(`/contracts/cancel/${id}`, {
        cancelDate: valuesCancelContract?.cancelDate,
        cancelDescription: valuesCancelContract?.cancelDescription,
      })
      .then((res) => {
        handleGetAlert({ message: res.data.message });
        setAttTable((item) => !item);
        setOpenModal(false);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  return { handleCancelContract, loading };
};
