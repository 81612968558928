import { Children } from "react";
import {
  Box,
  Grid,
  useTheme,
  Typography,
  useMediaQuery,
  Tooltip as TooltipMui,
} from "@mui/material";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";
import { CardCore } from "src/Pages/components/CardCore/CardCore";
import { CardBodyCore } from "src/Pages/components/CardCore/Components/CardBodyCore";
import { useContextDashboardContext } from "src/Contexts/Dashboard/ContextDashboardBroker";
import { FormatMoney } from "src/shared/Utils/FormatMoney";
import { useStyles } from "src/Pages/components/ModalFilterCore/Index";

export const PieGraphicIncomingExpense = () => {
  const classes = useStyles();
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const { graphicOperators } = useContextDashboardContext();

  const dataGraphic = graphicOperators.data.sort(
    (a, b) => b.totalMonthly - a.totalMonthly
  );

  const COLORS = [
    "#4DE18C",
    "#4a90e2",
    "#efc716",
    "#f45d01",
    "#934ad2",
    "#ffd300",
    "#0ed8c9",
    "#f442a3",
    "#804000",
    "#002080",
    "#ff5733",
    "#ff005a",
    "#008080",
    "#d4ac0d",
    "#7d5afc",
    "#660066",
    "#1f618d",
    "#ff8800",
    "#004d00",
    "#6c3483",
  ];

  return (
    <CardCore sxStyle={{ margin: "0", height: "100%" }}>
      <CardBodyCore title="Produção por operadoras">
        <Grid container>
          <Grid item xs={6}>
            <ResponsiveContainer
              height={matches ? 300 : 200}
              style={{ padding: "0" }}
            >
              <PieChart style={{ padding: 0, margin: 0 }}>
                <Tooltip
                  labelStyle={{ color: "gray" }}
                  formatter={(value, name) => {
                    return [`${name}: ${Number(value).toFixed(2)}%`];
                  }}
                />

                <Pie
                  data={dataGraphic}
                  dataKey="percentage"
                  innerRadius={matches ? 70 : 55}
                  outerRadius={matches ? 90 : 70}
                  paddingAngle={4}
                >
                  {dataGraphic.map((item, index) => (
                    <Cell
                      key={`cell-${item.name}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </Grid>

          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Box
              className={classes.customScrollbar}
              sx={{
                maxHeight: matches ? "300px" : "200px",
                overflow: "auto",
                width: "200px",
              }}
            >
              {Children.toArray(
                dataGraphic.map((item, index) => (
                  <TooltipMui title={`R$ ${FormatMoney(item.totalMonthly)}`}>
                    <Box
                      key={item.name}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Box
                        sx={{
                          width: matches ? "12px" : "8px",
                          height: matches ? "12px" : "8px",
                          marginRight: matches ? "8px" : "6px",
                          borderRadius: "50%",
                          background: COLORS[index % COLORS.length],
                        }}
                      />

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          "& > *": { fontWeight: "500" },
                        }}
                      >
                        <Typography
                          sx={{ fontSize: { xs: "12px", md: "14px" } }}
                        >
                          {item.name}:
                        </Typography>

                        <Typography
                          sx={{ fontSize: { xs: "12px", md: "14px" } }}
                        >
                          {item.percentage.toFixed(2)}%
                        </Typography>
                      </Box>
                    </Box>
                  </TooltipMui>
                ))
              )}
            </Box>
          </Grid>
        </Grid>
      </CardBodyCore>
    </CardCore>
  );
};
