import { useEffect } from "react";
import { DashboardValues } from "./DashboardValues";
import { EvolutionLives } from "./EvolutionLives";
import { UseContractInternalDashboard } from "./Hooks/UseFormMonthByMonthProfitChart";

export interface IContractInternalDashboard {
  idContract: string;
}
export const ContractInternalDashboard = ({
  idContract: id,
}: IContractInternalDashboard) => {
  const { handleSubmit, valueProfitDashboard } =
    UseContractInternalDashboard(id);

  useEffect(() => {
    handleSubmit();
  }, []);

  return (
    <>
      <DashboardValues valueProfitDashboard={valueProfitDashboard} />
      <EvolutionLives valueProfitDashboard={valueProfitDashboard} />
    </>
  );
};
