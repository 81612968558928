import { alpha, useTheme, Typography } from "@mui/material";

import {
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
  Line,
  LineChart,
} from "recharts";
import { CardCore } from "src/Pages/components/CardCore/CardCore";
import { CardBodyCore } from "src/Pages/components/CardCore/Components/CardBodyCore";
import { FormatMoney } from "src/shared/Utils/FormatMoney";
import { IValueProfitDashboard } from "../Hooks/UseFormMonthByMonthProfitChart";
import { FormatDateBR } from "src/shared/Utils/FormatDateBR";
import React, { Children } from "react";

interface TooltipProps {
  active?: boolean;
  payload?: Array<{
    payload: {
      installmentNumber?: string;
      value?: number;
      dateReceived: string;
    };
    name: string;
    value: number;
  }>;
  labelStyle?: React.CSSProperties;
}

const CustomTooltip: React.FC<TooltipProps> = ({
  active,
  payload,
  labelStyle,
}) => {
  if (!active || !payload || payload.length === 0) {
    return null;
  }

  const data = payload[0].payload;

  return (
    <CardCore>
      <Typography style={labelStyle}>
        {`Data do recebimento: ${FormatDateBR(data.dateReceived)}`}
      </Typography>

      <Typography style={labelStyle} mb={2}>
        {`Parcela: ${data.installmentNumber}`}
      </Typography>

      {Children.toArray(
        payload.map((item) => {
          return (
            <Typography>{`${item.name}: ${Number(item.value)}`}</Typography>
          );
        })
      )}
    </CardCore>
  );
};
interface IDashboardValues {
  valueProfitDashboard: IValueProfitDashboard | undefined;
}

export const EvolutionLives = (data: IDashboardValues) => {
  const { valueProfitDashboard } = data;
  if (!valueProfitDashboard) return null;
  const theme = useTheme();

  if (valueProfitDashboard.data.length < 3) {
    valueProfitDashboard.data.unshift({
      commissionValue: 0,
      valuePayment: 0,
      dateReceived: "",
      id: 0,
      installmentNumber: 0,
      lives: 0,
    });
  }

  const colorStroke = "#b8b8b8";

  return (
    <CardCore sxStyle={{ margin: "1rem 0", marginBottom: "24px" }}>
      <CardBodyCore title="Evolução de vidas">
        <ResponsiveContainer height={350} width="100%">
          <LineChart data={valueProfitDashboard.data}>
            <XAxis // barra de baixo
              dataKey="dateReceived"
              fontSize={14}
              stroke={colorStroke}
              tickFormatter={(value) => FormatDateBR(value)}
            />

            <YAxis // barra lateral com valores
              fontSize={12}
              stroke={colorStroke}
              tickFormatter={(value) => FormatMoney(String(value))}
              tickLine={false}
              type="number"
              domain={[0, "dataMax + 2"]}
            />

            <CartesianGrid // grid
              stroke={alpha(colorStroke, 0.7)}
              strokeDasharray="3 3"
            />

            <Tooltip
              content={<CustomTooltip />}
              labelStyle={{ color: colorStroke }}
            />

            <Line
              dot
              dataKey="lives"
              fill="url(#colorPv)"
              name="Vidas"
              stroke={theme.palette.primary.main}
              strokeWidth={2}
              type="bump"
            />
          </LineChart>
        </ResponsiveContainer>
      </CardBodyCore>
    </CardCore>
  );
};
