import { ChangeEvent } from "react";
import { formatString } from "@format-string/core";
import { Box, MenuItem } from "@mui/material";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { IValuesFilterInstallments } from "src/Contexts/Lancamentos/Contrato/ContratosTypes";
import { AppTextField } from "src/Pages/components";
import { DividerCore } from "src/Pages/components/DividerCore/DividerCore";
import { ModalFilterCore } from "src/Pages/components/ModalFilterCore/Index";
import { apenasNumeros } from "src/shared/Utils/FormatMoney";

import { useHttpTableInstallments } from "../../table/Hooks/useHttpTableInstallments";
import { valuesDefaultFilterInstallments } from "src/Contexts/Lancamentos/Contrato/valuesDefaultInputsContratos/ValueDefaultInputContratos";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useParams } from "react-router";

export const FilterSidebarInstallments = () => {
  const { handleGet } = useHttpTableInstallments();
  const { id } = useParams();

  const { valuesFilterInstallments, setValuesFilterInstallments } =
    useContextContrato();

  const { setAttTable } = useConfigPageContext();

  const handleChange =
    (
      prop: keyof IValuesFilterInstallments,
      option?: "apenasNumero" | "CPF_CNPJ"
    ) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      if (option === "apenasNumero") {
        if (event.target.value.length <= 5) {
          setValuesFilterInstallments({
            ...valuesFilterInstallments,
            [prop]: apenasNumeros(event.target.value),
          });
        }
      } else if (option === "CPF_CNPJ") {
        setValuesFilterInstallments({
          ...valuesFilterInstallments,
          [prop]: formatString({
            type: "cpfOurCnpj",
            value: event.target.value,
          }),
        });
      } else {
        setValuesFilterInstallments({
          ...valuesFilterInstallments,
          [prop]: event.target.value,
        });
      }
    };

  const ClearFilter = async () => {
    setAttTable((item) => !item);
    setValuesFilterInstallments(valuesDefaultFilterInstallments);
  };

  const FilterSearch = async () => {
    await handleGet();
  };

  return (
    <ModalFilterCore FilterClear={ClearFilter} FilterSearch={FilterSearch}>
      {!id && (
        <>
          <DividerCore
            sxStyle={{ marginBottom: "12px", marginTop: "0" }}
            title="Proposta"
          />
          <Box
            id="container propostas"
            sx={{
              "& > .MuiAutocomplete-root, & > .MuiTextField-root": {
                flexWrap: "wrap",
                display: "flex",
                marginBottom: "1rem",
              },
            }}
          >
            {!id && (
              <>
                <AppTextField
                  label="Código proposta"
                  value={valuesFilterInstallments.proposalCode}
                  onChange={handleChange("proposalCode")}
                />

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "1rem",
                    alignItems: "center",
                  }}
                >
                  <AppTextField
                    label="Data assinatura"
                    sxStyle={{ width: "45%" }}
                    type="date"
                    value={valuesFilterInstallments.startSignatureDate}
                    onChange={handleChange("startSignatureDate")}
                  />
                  até
                  <AppTextField
                    label="Data assinatura"
                    sxStyle={{ width: "45%" }}
                    type="date"
                    value={valuesFilterInstallments.endSignatureDate}
                    onChange={handleChange("endSignatureDate")}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "1rem",
                    alignItems: "center",
                  }}
                >
                  <AppTextField
                    label="Data emissão"
                    sxStyle={{ width: "45%" }}
                    // type="date"
                    value={valuesFilterInstallments.startDateEmission}
                    onChange={handleChange("startDateEmission")}
                    disabled
                    tooltip="Em desenvolvimento"
                  />
                  até
                  <AppTextField
                    label="Data emissão"
                    sxStyle={{ width: "45%" }}
                    // type="date"
                    value={valuesFilterInstallments.endDateEmission}
                    onChange={handleChange("endDateEmission")}
                    disabled
                    tooltip="Em desenvolvimento"
                  />
                </Box>
              </>
            )}
          </Box>

          <DividerCore sxStyle={{ marginBottom: "12px" }} title="Cliente" />
          <Box
            id="container cliente"
            sx={{
              "& > .MuiAutocomplete-root, & > .MuiTextField-root": {
                flexWrap: "wrap",
                display: "flex",
                marginBottom: "1rem",
              },
            }}
          >
            <AppTextField
              label="Titular/Razão"
              sxStyle={{ width: "100%" }}
              value={valuesFilterInstallments.customerName}
              onChange={handleChange("customerName")}
            />
            <AppTextField
              label="CPF/CNPJ"
              sxStyle={{ width: "100%" }}
              value={valuesFilterInstallments.customerDocument}
              onChange={handleChange("customerDocument", "CPF_CNPJ")}
            />
          </Box>
        </>
      )}

      <DividerCore sxStyle={{ marginBottom: "12px" }} title="Parcelas" />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "1rem",
          alignItems: "center",
        }}
      >
        <AppTextField
          label="Data de recebimento"
          sxStyle={{ width: "45%" }}
          type="date"
          value={valuesFilterInstallments.startDateReceipt}
          onChange={handleChange("startDateReceipt")}
        />
        até
        <AppTextField
          label="Data recebimento"
          sxStyle={{ width: "45%" }}
          type="date"
          value={valuesFilterInstallments.endDateReceipt}
          onChange={handleChange("endDateReceipt")}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "1rem",
          alignItems: "center",
        }}
      >
        <AppTextField
          label="Data de vencimento"
          sxStyle={{ width: "45%" }}
          type="date"
          value={valuesFilterInstallments.startDueDate}
          onChange={handleChange("startDueDate")}
        />
        até
        <AppTextField
          label="Data de vencimento"
          sxStyle={{ width: "45%" }}
          type="date"
          value={valuesFilterInstallments.endDueDate}
          onChange={handleChange("endDueDate")}
        />
      </Box>
      <Box
        id="container cliente"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "1rem",
          alignItems: "center",
        }}
      >
        <AppTextField
          select
          disabled
          tooltip="Em desenvolvimento"
          id="parcelas_recebida"
          label="Parcelas recebidas ?"
          sxStyle={{ width: "48%", opacity: "", pointerEvents: "auto" }}
          value={valuesFilterInstallments.installmentReceipt}
          onChange={handleChange("installmentReceipt")}
        >
          <MenuItem value="ALL">Todas</MenuItem>
          <MenuItem value="TRUE">Recebidas</MenuItem>
          <MenuItem value="FALSE">Não recebidas</MenuItem>
        </AppTextField>

        <AppTextField
          disabled
          tooltip="Em desenvolvimento"
          label="Numero da parcela"
          sxStyle={{ width: "48%" }}
          type="number"
          value={valuesFilterInstallments.installmentNumber}
          onChange={handleChange("installmentNumber", "apenasNumero")}
        />
      </Box>
    </ModalFilterCore>
  );
};
