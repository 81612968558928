import React, { useEffect } from "react";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { ModalCore } from "src/Pages/components";

import { FormCancelContract } from "./FormCancelContract";
import { UseFormModalCancelContract } from "./UseFormFormCancelContract";

interface IModalCancelContract {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalCancelContract = ({
  openModal,
  setOpenModal,
}: IModalCancelContract) => {
  const { setValuesInputUpdateInstallment } = useContextContrato();

  useEffect(() => {
    if (!openModal) {
      setValuesInputUpdateInstallment(undefined);
    }
  }, [openModal]);

  const { handleCancelContract, loading: loadingCancelContract } =
    UseFormModalCancelContract({ setOpenModal });

  return (
    <ModalCore
      loadingSubmit={loadingCancelContract}
      open={openModal}
      setOpen={setOpenModal}
      sxStyle={{ width: "500px" }}
      title="Cancelar contrato"
      titleSubmit="Cancelar contrato"
      onChange={handleCancelContract}
    >
      <FormCancelContract />
    </ModalCore>
  );
};
