import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { CardHeaderCore } from "../CardCore/Components/CardHeaderCore";
import { UrlConfig } from "src/shared/Utils/paths";
import { LinkCore } from "../LinkCore/LinkCore";
import { ButtonCore } from "../ButtonCore/ButtonCore";
import { RedeemIcon } from "../Icons/Icons";
import moment from "moment";
import { alpha } from "@mui/material";

export const HeaderIsPlanTest = () => {
  const { userPerfil } = useAuthContext();

  if (!userPerfil) return null;

  const isViewModal =
    userPerfil?.subscription?.isActive === false ||
    userPerfil?.subscription === null;

  function calculateRemainingDays() {
    const today = moment(); // Data atual
    const endOfTrial = moment(userPerfil?.createdAt).add(7, "days"); // Adicionando 7 dias
    const remainingDays = endOfTrial.diff(today, "days"); // Diferença em dias
    return remainingDays > 0 ? remainingDays : 0; // Retorna 0 se o teste já expirou
  }

  return (
    <>
      {isViewModal && (
        <CardHeaderCore
          buttonAdd={[
            <LinkCore key={1} to={UrlConfig.plans.url}>
              <ButtonCore title="Conhecer planos" />
            </LinkCore>,
          ]}
          icon={
            <RedeemIcon
              fontSize="small"
              sx={{ display: { xs: "none", sm: "block" } }}
            />
          }
          sxStyle={{
            border: (theme) =>
              `2px solid ${alpha(theme.palette.primary.main, 0.8)}`,
            margin: "16px 0",
            marginBottom: "16px",
          }}
          title={`Você não possui um plano ativo! Aproveite o(s) ${calculateRemainingDays()} dia(s) de período de avaliação.
              `}
        />
      )}
    </>
  );
};
